<template>
  <v-row>
    <v-col cols="6">
      <v-card>
        <EditarCliente class="px-10" />
      </v-card>
    </v-col>
    <v-col cols="6" text-center>
      <v-img
        fluid
        src="../../../../public/img/cliente.svg"
        class="imagen-personalizada"
      ></v-img>
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {
    EditarCliente: () =>
      import("@/components/folderEntities/Cliente/EditarClienteComponent.vue"),
  },
  mounted() {
    this.$store.state.mainTitle = `EDITAR CLIENTE`;
  },
};
</script>

<style scoped>
.imagen-personalizada {
  margin: 5%;
  padding: auto;
  max-width: 100%; /* Ajusta el valor según tus necesidades */
  height: auto; /* Esto asegura que la altura se ajuste automáticamente */
}
</style>
